import React, { useState, useRef, useEffect } from "react";
import Lottie from "lottie-react";
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby";
import { getSourceSet } from '../utils';
import { graphql } from 'gatsby'
import { Typography } from "@material-ui/core";
import { StaticImage } from "gatsby-plugin-image";
import Etimology from '../Etimology.json';
import Catalina from '../Catalina.json';
import invoice from '../invoice.json';
import {Grid, Container, TextField, Fab} from '@material-ui/core/';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from "@mui/material";
import { useTheme } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import FAQSection from "../FAQSection";
import branding from '../images/branding.mp4';
import clientPortal from '../images/clientportal.mp4'
import macbook from '../images/brandingmac.png';

const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const docLink = {
  text: "Documentation",
  url: "https://www.gatsbyjs.com/docs/",
  color: "#8954A8",
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}




const links = [
  {
    text: "Tutorial",
    url: "https://www.gatsbyjs.com/docs/tutorial/",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
    color: "#E95800",
  },
  {
    text: "How to Guides",
    url: "https://www.gatsbyjs.com/docs/how-to/",
    description:
      "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
    color: "#1099A8",
  },
  {
    text: "Reference Guides",
    url: "https://www.gatsbyjs.com/docs/reference/",
    description:
      "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
    color: "#BC027F",
  },
  {
    text: "Conceptual Guides",
    url: "https://www.gatsbyjs.com/docs/conceptual/",
    description:
      "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
    color: "#0D96F2",
  },
  {
    text: "Plugin Library",
    url: "https://www.gatsbyjs.com/plugins",
    description:
      "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
    color: "#8EB814",
  },
  {
    text: "Build and Host",
    url: "https://www.gatsbyjs.com/cloud",
    badge: true,
    description:
      "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
    color: "#663399",
  },
]

const IndexPage = (props) => {

  const data = props.data;
  const structuredData = `
  {
    "@context": "http://schema.org",
    "@type": "SportsActivityLocation",
    "@id": "${data.site.siteMetadata.siteUrl}",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Mclean",
      "addressRegion": "VA",
      "postalCode":"22102",
      "streetAddress": "8300 Greensboro Drive "
    },
    "name": "Metalinguist",
    "telephone": "+15714820147",
    "openingHoursSpecification": 
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday"
        ],
        "opens": "08:00",
        "closes": "20:00"
      }
    ,
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "38.7716198",
      "longitude": "-77.1098972"
    },
    "url": "https://metalinguist.com",
    "sameAs" : ["https://twitter.com/metalinguistllc"]
  }`;

  const [open, setOpen] = useState(false);
  const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const playOnIntersect2 = (entries) => {
      console.log("intersecting homie");
      console.log(entries);
    }
    const [hasRef, setHasRef] = useState(false);
    const [intersected, setIntersected] = useState(false);

    const subHeading = {
      lineHeight: xsScreen ? '25px' : '36px',
      fontSize: '1.75em',
      fontWeight: 'medium'
    }


    const subContent = {
      fontFamily: 'Poppins',
      fontSize: '1.5em',
      fontWeight: 'medium',
      lineHeight: xsScreen ? '25px' : '35px',

    }
   
    useEffect(() => {
      debugger;
      if (hasRef) {
          const observer = new IntersectionObserver(entries => {
              entries.forEach(entry => {
                  if (entry.isIntersecting) {
                    setIntersected(true);
                      // Do something when the element is in view
                  } else {
                      // Do something when the element is out of view
                  }
              });
          });
  
          observer.observe(hasRef);
  
          // Cleanup on component unmount
          return () => {
              observer.disconnect();
          };
      }
  }, [hasRef]);

  return (<Layout currentPage="home" overridePadding={mdScreen ? true: false}>
    <SEO title={'Home'} 
      structuredData={structuredData}/>
      <Grid container column spacing={smScreen ? 1 : 8} style={{paddingTop: '5em', fontSize: xsScreen ? '10px' : smScreen ? '10px' : mdScreen ? '12px' : '16px'}}>
        {xsScreen ?
        <Grid container item row justifyContent="center" style={{fontSize: xsScreen ? '10px' : smScreen ? '10px' : mdScreen ? '12px' : '16px'}}>
          <Grid item sm={12} style={{textAlign: 'center'}}><Typography variant={"h2"} style={{fontFamily: 'Poppins', paddingBottom: '.5em', fontWeight: 550, fontSize: '3em', lineHeight: xsScreen ? '40px' : mdScreen ? '50px': '61px'}}>
          Explore the Next Generation of Translation Business Management Systems (TBMS).
            </Typography>
          </Grid>
          <Grid item sm={6} style={{order: xsScreen ? '1' : '2'}}>
            <Lottie animationData={Etimology} loop={false}/>
          </Grid>
          <Grid item sm={6}  style={{padding: '2.5em', paddingTop: '0px', paddingBottom: '25px', order: xsScreen ? '2' : '1', textAlign: 'center'}}>
          <Typography variant="subtitle" style={{fontFamily: 'Source Sans Pro', ...subHeading, textAlign: 'center'}}>
          Prioritize your business needs, and leave behind redundant TMS features already available in CAT suites. Modern and at the fraction of the cost - welcome to the future of language business management.            </Typography ><br></br>
            <Grid container spacing={2} style={{alignItems: 'center', justifyContent: 'center', paddingTop: '2.5em'}}>
              {/* <Grid item lg="6"><TextField autofocus label="Email Address" variant="standard" color="primary" focused fullWidth></TextField></Grid> */}
              <Fab variant="extended" color="primary" style={{background: 'linear-gradient(180deg, #ffffff, #c4c4c4', fontSize: smScreen ? '10px' : 'auto', height: smScreen ? '25px' : '40px', marginRight: '1em'}}><Link to="/contact"><b style={{fontFamily: 'Source Sans Pro', fontSize: '1em', color: '#36566E'}}>Contact Sales</b></Link></Fab>
              <Fab variant="extended" color="primary" onClick={() => window.location.href = 'https://app.metalinguist.com/onboarding'} style={{background: 'linear-gradient(180deg, #53768e, #254D6A)', fontSize: smScreen ? '10px' : 'auto', height: smScreen ? '25px' : '40px'}}><b style={{fontFamily: 'Source Sans Pro', fontSize: '1em'}}>Sign Up</b></Fab>
            </Grid>
          </Grid>
        </Grid>
        : <Grid container item row justifyContent="center" style={{fontSize: xsScreen ? '10px' : smScreen ? '10px' : mdScreen ? '12px' : '16px'}}>
          <Grid item sm={6}  style={{padding: '2.5em', paddingTop: '0px', paddingBottom: '25px', order: xsScreen ? '2' : '1'}}>
            <Typography variant={"h2"} style={{fontFamily: 'Avenir', paddingBottom: '.5em', fontWeight: 550, fontSize: '3em', lineHeight: xsScreen ? '40px' : mdScreen ? '50px' : '61px'}}>
            Explore the Next Generation of Translation Business Management Systems (TBMS).
            </Typography>
            <Typography variant="subtitle" style={{fontFamily: 'Source Sans Pro', ...subHeading, textAlign: 'center'}}>
            Prioritize your business needs, and leave behind redundant TMS features already available in CAT suites. Modern and at the fraction of the cost - welcome to the future of language business management.            </Typography ><br></br>
            <Grid container spacing={2} style={{alignItems: 'flex-start', paddingTop: '2.5em'}}>
              {/* <Grid item lg="6"><TextField autofocus label="Email Address" variant="standard" color="primary" focused fullWidth></TextField></Grid> */}
              <Fab variant="extended" color="primary" style={{background: 'linear-gradient(180deg, #ffffff, #c4c4c4', fontSize: smScreen ? '10px' : 'auto', height: smScreen ? '25px' : '40px', marginRight: '1em'}}><Link to="/contact"><b style={{fontFamily: 'Source Sans Pro', fontSize: '1em', color: '#36566E'}}>Contact Sales</b></Link></Fab>
              <Fab variant="extended" color="primary" onClick={() => window.location.href = 'https://app.metalinguist.com/onboarding'} style={{background: 'linear-gradient(180deg, #53768e, #254D6A)', fontSize: smScreen ? '10px' : 'auto', height: smScreen ? '25px' : '40px'}}><b style={{fontFamily: 'Source Sans Pro', fontSize: '1em'}}>Sign Up</b></Fab>
            </Grid>
          </Grid>
          <Grid item sm={6} style={{order: xsScreen ? '1' : '2'}}>
            <Lottie animationData={Etimology} loop={false}/>
          </Grid>
        </Grid>}
        <Grid container item row justifyContent="center" style={{position: 'relative'}}>
          <Grid  style={{position: 'absolute', backgroundColor: 'rgb(87 88 94 / 21%)', height: '100%', width: '200%', WebkitBackdropFilter: 'blur(15px)', backdropFilter: 'blur(15px)' }}></Grid>
          <Grid item sm={6} style={{display: 'flex', zIndex: 1, justifyContent: 'center', order: smScreen ? 2 : 1, position: 'relative', width: xsScreen ? '40em' : 'inherit', height: xsScreen ? '32em' : '30em'}}>
            <div style={{width: '100%', height: '100%', position: 'relative', marginTop: xsScreen ? '1em' : smScreen ? '4em' : '3em', marginLeft: xsScreen ? '0' : '10%'}}>
            <StaticImage src="../images/home_portal.png"
              quality={95}
              placeholder={'TRACED_SVG'}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Metalinguist"
              fadeIn={true} 
              loading="eager"
              style={{ height: '27em', width: '36em',position: 'absolute', zIndex: 1}}
              objectFit="contain"
            />
            <video id="portal" src={clientPortal} type="video/mp4" loop autoPlay muted playsInline style={{
              height: '18em',
              width: '27em',
              objectFit: 'scale-down',
              left: '4.5em', position: 'absolute'
              }}>
            </video>
            </div>
          </Grid>
          <Grid item sm={6} style={{textAlign: 'left', zIndex: 2, padding: '2.5em', order: xsScreen ? 1 : 2, display: 'flex', flexFlow: 'column', justifyContent: 'center'}}>
            <Typography variant="h4" style={{fontWeight: 550, fontSize: '2.5em'}}>Client Portal</Typography>
            <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontFamily: 'Source Sans Pro', fontSize: '1.25em', ...subContent,  marginRight: smScreen ? '0' : '2.5em',}}>
            Setting a new translation industry standard for sleek, intuitive design, our client portal makes you feel like an expert from the first click. Designed to put humans in charge, not the computer, you’ll wonder how you ever managed without it.
            </Typography>
            {/* <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontFamily: 'Source Sans Pro', fontSize: '1.25em'}}>
            Our intuitive user interface allows your clients to painlessly acclimate, onboard and create translation projects within minutes. Say goodbye to email, and hello to a portal that your clients will actually enjoy.
            </Typography> */}
          </Grid>
        </Grid>
        <Grid container item row justifyContent="center" style={{position: 'relative'}}>
          <Grid  style={{position: 'absolute', zIndex: 0, WebkitBackdropFilter: 'blur(15px)', backdropFilter: 'blur(15px)', height: '100%', width: '200%', backgroundColor: 'rgb(198 198 215 / 25%)'}}></Grid>
          <Grid item sm={6} style={{textAlign: 'left', padding: xsScreen ? '2.5em' : '8em 2.5em 8em 2.5em',  zIndex: 1, display: 'flex', flexFlow: 'column', justifyContent: 'center'}}>
            <Typography variant="h4" style={{fontWeight: 550, fontSize: '2.5em'}}>
              Invoicing and Payment Processing
            </Typography>
            <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontFamily: 'Source Sans Pro', fontSize: '1.25em', ...subContent}}>
            Prioritize your business needs, and leave behind redundant TMS features already available in CAT suites. Modern and at the fraction of the cost - welcome to the future of language business management. 
            </Typography>
          </Grid>
          <Grid item sm={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1, paddingTop: xsScreen ? '0em' : '5em', paddingBottom: xsScreen ? '5em' : '0em'}}>
            <Lottie animationData={invoice} loop={true} style={{height: '17em'}}/>
          </Grid>
        </Grid>
        <Grid container item row justifyContent="center" style={{position: 'relative'}}>
          <Grid  style={{position: 'absolute', backgroundColor: 'rgb(87 88 94 / 21%)', height: '100%', width: '200%', WebkitBackdropFilter: 'blur(15px)', backdropFilter: 'blur(15px)' }}></Grid>
          <Grid item sm={6} style={{display: 'flex', zIndex: 1, justifyContent: 'center', order: smScreen ? 2 : 1, position: 'relative', width: xsScreen ? '40em' : 'inherit', height:  xsScreen ? '25em' : '25em'}}>
            <div style={{width: '100%', height: '100%', position: 'relative', marginTop: xsScreen ? '1em' : '4em', marginLeft: xsScreen ? '0' : '10%'}}>
            <StaticImage src="../images/brandingmac.png"
              quality={95}
              placeholder={'TRACED_SVG'}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Metalinguist"
              fadeIn={true} 
              loading="eager"
              style={{ height: '20.5em', width: '35em',position: 'absolute', zIndex: 1}}
              objectFit="contain"
            />
            <video id="branding" loop autoPlay muted playsInline style={{
              height: '18.5em',
              width: '27em',
              objectFit: 'cover',
              left: '4em', position: 'absolute'
              }}>
                <source src={branding} type="video/mp4" />
                {/* <source src="http://www.sample-videos.com/video/mp4/720/big_buck_bunny_720p_1mb.mp4" type="video/ogg" /> */}
                Your browser does not support the video tag.
            </video>
            </div>
          </Grid>
          <Grid item sm={6} style={{textAlign: 'left', zIndex: 2, padding: xsScreen ? '2.5em' : mdScreen ? '1em' : '2.5em', marginTop: xsScreen ? '0' : mdScreen ? '3em' : '2em', order: xsScreen ? 1 : 2, display: 'flex', flexFlow: 'column', justifyContent: 'center'}}>
            <Typography variant="h4" style={{fontWeight: 550, fontSize: '2.5em'}}>Your Own Website and Brand</Typography>
            <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontFamily: 'Source Sans Pro', fontSize: '1.25em', ...subContent, marginRight: smScreen ? '0' : '2.5em'}}>
            Establish your professional brand with a fully customizable website and portal, tailored to your unique identity. Go live immediately with our default settings or customize your entire system in 30 minutes or less.
            </Typography>
            {/* <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontFamily: 'Source Sans Pro', fontSize: '1.25em'}}>
            Our intuitive user interface allows your clients to painlessly acclimate, onboard and create translation projects within minutes. Say goodbye to email, and hello to a portal that your clients will actually enjoy.
            </Typography> */}
          </Grid>
        </Grid>
        <Grid container item row justifyContent="center" style={{position: 'relative'}}  >
          <Grid  style={{position: 'absolute', zIndex: 0, WebkitBackdropFilter: 'blur(15px)', backdropFilter: 'blur(15px)', height: '90%', width: '200%', backgroundColor: 'rgb(198 198 215 / 25%)'}}></Grid>
          <Grid item sm={6} style={{textAlign: 'left', padding: '2.5em', zIndex: 1, display: 'flex', flexFlow: 'column', justifyContent: 'center'}}>
            <Typography variant="h4" style={{fontWeight: 550, fontSize: '2.5em'}}>
            CATalina: Your AI Personal Assistant
            </Typography>
            <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontFamily: 'Source Sans Pro', fontSize: '1.25em', ...subContent}}>
            CATalina is not just another assistant—she’s your ultimate project management tool. Automating routine tasks to answering translation questions, CATalina elevates your productivity as if you had a whole team behind you. Coming early 2024.
            </Typography>
          </Grid>
          <Grid item sm={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1, paddingBottom: xsScreen ? '5em' : '0em'}} ref={setHasRef}>
            {intersected && <Lottie animationData={Catalina} loop={false} autoplay style={{height: '30em'}} />}
          </Grid>
        </Grid>
        {/* <Grid container item row justifyContent="center" style={{paddingTop: '25px'}}>
          <Grid item sm={6} style={{display: 'flex', justifyContent: 'center', order: xsScreen ? 2 : 1}}>
            <StaticImage src="../images/ladingnew.png"
                quality={95}
                placeholder={'TRACED_SVG'}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Metalinguist"
                fadeIn={true} 
                loading="eager"
                style={{width: '75%'}}
                objectFit="contain"
              />
          </Grid> 
          <Grid item sm={6} style={{textAlign: 'left', padding: '2.5em', paddingTop: '0px', order: xsScreen ? 1 : 2}}>
            <Typography variant="h4" style={{fontWeight: 700, fontSize: '2.5em'}}>
              Automate Project/Quote Intake
            </Typography>
            <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontFamily: 'Source Sans Pro', fontSize: '1.25em'}}>
            Process orders and quotes, automatically propagate projects into your pipeline, manage users, send invoices, and/or integrate with your favorite tools or 
            Translation Management System (TMS) &#8212; all without the stress, confusion and error of email threads or random FTP downloads.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item row justifyContent="center">
          <Grid item sm={6} style={{padding: '2.5em', paddingTop: '25px'}}>
            <Typography variant="h4" style={{fontWeight: 700, fontSize: '2.5em'}}>No Training Time</Typography>
              <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontFamily: 'Source Sans Pro', fontSize: '1.25em'}}>
              Skip the endless training, lengthy onboarding, or systems that require dedicated staff to manage. 
              In an hour or less, choose either our out-of-the-box solution, or use our industry-leading UX simulation experience to make your own portal 
              &#8212; without the front to backend toggling.
              </Typography>
          </Grid>
          <Grid item sm={6} style={{display: 'flex', justifyContent: 'center'}}>
            <StaticImage src="../images/notraining.png"
                quality={95}
                placeholder={'TRACED_SVG'}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Metalinguist"
                fadeIn={true} 
                loading="eager"
                style={{width: '70%'}}
                objectFit="contain"
              />
          </Grid> 
        </Grid> */}
        <Grid container item row justifyContent="center" style={{position: 'relative', paddingTop: '75px', paddingBottom: '75px'}}>
          {/* <Grid item container xs={12} md={6} style={{justifyContent: 'center'}}>
            <Typography variant="h3" style={{whiteSpace: 'nowrap'}}>{xsScreen ? 'F.A.Q' : 'Frequently Asked Questions'}</Typography>
            <FAQSection />
          </Grid> */}
        </Grid>
        
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container justify="center" style={{height: '100%', alignItems: 'center'}}>
            <Box style={{display: 'flex', justifyContent: 'center', backgroundColor: 'transparent', height: 'fit-content', overflowY: 'scroll', position: 'relative'}}>
              <IconButton onClick={() => setOpen(false)} style={{position: 'absolute', top: '.5em', right: '3em', color: 'white'}}><CloseIcon /></IconButton>
              <iframe src='https://formcrafts.com/a/qxndssw?iframe=true&resizer=false&innerHeight=1173' width='612' height='1173' style={{maxWidth: '85%', maxHeight: '700px', border: '0'}}></iframe>
            </Box>
          </Grid>
      </Modal>
      </Grid>
   </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    site: site {
      siteMetadata {
        title
        description
        author
        keywords
        siteUrl
      }
    }
  }
`